




















































































































import {
  SfMegaMenu,
  SfProductCard,
  SfScrollable,
  SfMenuItem,
  SfButton,
  SfLoader,
  SfIcon,
} from '@storefront-ui/vue';
import { defineComponent, useRouter, useContext } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import SvgImage from '~/components/General/SvgImage.vue';
import type { Product } from '~/modules/catalog/product/types';

export default defineComponent({
  name: 'SearchResults',
  components: {
    SfMegaMenu,
    SfProductCard,
    SfScrollable,
    SfMenuItem,
    SfButton,
    SvgImage,
    SfLoader,
    SfIcon,
    SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    ProductContainerHorizontal: () => import('~/components/Custom/Organisms/ProductContainerHorizontal.vue')
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    searchResults: {
      type: Array as PropType<Product[] | null>,
      default: () => [],
    },
    searchLoading: {
      type: Boolean,
      default: false,
    },
    searchTerm: {
      type: String,
      default: false,
    },
  },
  emits: ['clearSearch'],
  setup(props, { emit }) {

    const router = useRouter();
    const { app } = useContext();

    const clearSearchResult = (() => {
      emit('clearSearch');
    });
    
    const gotoAllResults = async () => {
      // TODO: dynamic term to be updated
      await router.push(app.localeRoute({ name: 'search-results', query: { term: props.searchTerm } }));
    };

    return {
      clearSearchResult,
      gotoAllResults,
    };
  },
});
